import React from 'react';
import { useParams } from 'react-router-dom';
import { appSettings } from '../../AppSettings';
// import i18n from '../../Core/i18n/i18n';
import { useTranslation } from 'react-i18next';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUp: React.FC = ({ children }) => {
    //const [signUpFlow, setSignUpFlow] = useState<SignUpFlow | null>(null);

    //todo JK: ;)
    const { lang } = useParams();

    if (lang) {
        appSettings.language = lang;
    }

    const { i18n } = useTranslation();

    i18n.changeLanguage(appSettings.language);

    /*
    useEffect(() => {
        const doGetFlow = async () => {
            const flow = await getFlow('attendee');
            if (flow && flow.content && flow.content.validationRules) {
                flow.content.validationRules['Email'] = [
                    { validationAction: email },
                    { validationAction: required },
                ];
                flow.content.validationRules['PhoneNumber'] = [
                    { validationAction: phoneNumber },
                ];
            }
            setSignUpFlow(flow);
        };
        doGetFlow();
    }, []);
    */
    return (
        <div
            css={css`
                font-size: 14px;
            `}
        >
            <div
                css={css`
                    text-align: center;
                `}
            >
                <img
                    css={css`
                        border: 0;
                        width: 40%;
                        margin-top: 15px;
                    `}
                    alt=""
                    src="/injure-logo.png"
                ></img>
            </div>
            {children}
        </div>
    );
};

export default SignUp;
