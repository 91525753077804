const resources = {
    en: {
        translation: {
            'signup.form.ticket-help':'In case of registration problems please contact:',
            'signup.form.button-next': 'Next',
            'signup.form.button-previous': 'Previous',
            'signup.form.button-submit': 'Submit',
            'signup.form.total-price': 'Total price',
        },
    },
    pl: {
        translation: {
            'signup.form.ticket-help':'W razie problemów z zakupem biletów prosimy o kontakt na adres:',
            'signup.form.button-next': 'Dalej',
            'signup.form.button-previous': 'Wstecz',
            'signup.form.button-submit': 'Wyślij',
            'signup.form.total-price': 'Całkowity koszt',
        },
    },
};

export default resources;


