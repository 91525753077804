/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpTerms: React.FC = () => {
    return (
        <div>
            <div
                css={css`
                    font-size: 20px;
                    text-align: center;
                    margin-top: 40px;
                    margin-bottom: 40px;
                `}
            >
                Regulamin sprzedaży
            </div>
            <div
                css={css`
                    font-size: 16px;
                    margin-left: 30px;
                    margin-right: 30px;
                    line-height: 2;
                `}
            >
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia ogólne:
                </p>
                1. Niniejszy regulamin określa zasady i warunki internetowej
                sprzedaży biletów wstępu na festiwal muzyczny Injure Grind
                Attack, organizowany przez spółkę Injure Grind sp. z o.o. non
                profit, ul. Równoległa 21, 42-400 Zawiercie, NIP: 6492332678,
                REGON: 528266440 zwanej dalej “Spółką”, oraz gadżetów
                festiwalowych.
                <br />
                2. Regulamin dostępny jest na stronie internetowej festiwalu:
                https://injuregrindattack.pl.
                <br />
                3. Każda osoba nabywająca bilet i gadżety festiwalowe (zwana
                dalej “Klientem”) zobowiązana jest do zapoznania się z treścią
                regulaminu przed dokonaniem zakupu. Zakup biletu lub gadżetu
                festiwalowego jest równoznaczny z akceptacją postanowień
                regulaminu.
                <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Zakup Biletów i Gadżetów Festiwalowych:
                </p>
                1. Bilety na festiwal Injure Grind Attack oraz gadżety
                festiwalowe (m.in. koszulki, czapki, torby) są dostępne
                wyłącznie za pośrednictwem strony internetowej
                https://injuregrindattack.pl, zwanej dalej “Serwisem”. <br />
                2. Aby dokonać zakupu biletu i/lub gadżetów festiwalowych,
                Klient musi:
                <br />
                &emsp;&emsp; a. Wybrać odpowiedni rodzaj biletu. <br />
                &emsp;&emsp; b. Wybrać odpowiedni produkt i ilość (w przypadku
                zakupu gadżetów festiwalowych). <br />
                &emsp;&emsp; c. Wprowadzić wymagane dane osobowe, takie jak
                imię, nazwisko, adres poczty elektronicznej. <br />
                &emsp;&emsp; d. Dokonać płatności za pomocą platformy
                Przelewy24. <br />
                3. Po pomyślnym dokonaniu płatności, bilet zostanie przesłany na
                adres poczty elektronicznej podany przez Klienta w formacie PDF,
                gotowy do wydruku lub do okazania na urządzeniu mobilnym przy
                wejściu na teren festiwalu. Gadżety będą do odbioru w specjalnie
                oznaczonym punkcie na terenie festiwalu, po okazaniu
                potwierdzenia zamówienia.
                <br />
                4. Klient zobowiązany jest do sprawdzenia poprawności danych
                zawartych na bilecie. Wszelkie niezgodności należy niezwłocznie
                zgłosić Spółce. <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Zwroty i Reklamacje:
                </p>
                1. Zakupione w Serwisie bilety nie podlegają zwrotowi ani
                wymianie zgodnie z z art. 38 pkt 12 ustawy z dnia 30 maja 2014
                roku o prawach konsumenta (t.j. Dz.U. z 2020 r. poz. 287, ze
                zm.). <br />
                2. W przypadku odwołania festiwalu, Klientowi przysługuje zwrot
                pełnej kwoty za bilet. Procedura zwrotu zostanie określona na
                stronie internetowej festiwalu. <br />
                3. W przypadku zakupu gadżetów festiwalowych, w ciągu 14 dni od
                otrzymania produktów, Klientowi przysługuje prawo do odstąpienia
                od umowy bez podania przyczyny. Klient może skorzystać z tego
                prawa, powiadamiając Serwis poprzez wiadomość e-mail wysłaną na
                adres tickets@injuregrindattack.pl. <br />
                3. Po odstąpieniu Klient ma obowiązek niezwłocznie, nie później
                niż w terminie 14 dni od dnia, w którym odstąpił od umowy,
                zwrócić zamówione gadżety festiwalowe na adres Spółki: ul.
                Równoległa 21, 42-400 Zawiercie. <br />
                4. Reklamacje dotyczące biletów oraz gadżetów festiwalowych
                można składać drogą elektroniczną na adres
                tickets@injuregrindattack.pl. Serwis rozpatrzy reklamację w
                ciągu 30 dni od jej otrzymania. <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia końcowe:
                </p>
                1. Organizator zastrzega sobie prawo do zmiany niniejszego
                regulaminu. Wszelkie zmiany będą publikowane na stronie
                internetowej festiwalu i wchodzą w życie z dniem ich
                opublikowania. <br />
                2. W sprawach nieuregulowanych niniejszym regulaminem stosuje
                się przepisy prawa polskiego. <br />
            </div>
        </div>
    );
};

export default SignUpTerms;
