import React from 'react';
import NavBar from './layout/navbar';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

/* eslint-disable @typescript-eslint/no-unused-expressions */
jsx;

type Props = {
    children: JSX.Element | JSX.Element[];
};

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <div
            css={css`
                background-image: url(/icons/background.jpg);
                background-attachment: fixed;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                min-height: 100%;
            `}
        >
            <NavBar />
            <div
                css={css`
                    padding-top: 20px;
                    padding-bottom: 20px;
                `}
            >
                <div
                    css={css`
                        background-color: #fff;
                        padding: 25px;
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 1140px;
                        border-radius: 4px;
                        opacity: 0.97;
                        box-sizing: border-box;
                    `}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
