type AppSettings = {
    [key: string]: string;
};

export const authSettings = {
    domain: 'injuregrindattack-pl.eu.auth0.com',
    client_id: 'FVrIKAkaySnPwz89FDEBveMbt1rLF06N',
    redirect_uri: window.location.origin + '/signin-callback',
    scope: 'openid profile api email',
    audience: 'https://backend.injuregrindattack.pl',
};

const backendUrl = 'https://backend.injuregrindattack.pl';
//const backendUrl = 'https://localhost:44357';
const merchEnabled = 'Y';
const photoPath = '/photos';

export const appSettings = {
    ...authSettings,
    backendUrl,
    merchEnabled,
    photoPath,
} as AppSettings;
