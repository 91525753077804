export type TempAccreditation = {
    id: number;
    fullName: string;
    email: string;
    accreditationType: string;
    ticketCount: number;
    price: number;
    alreadyPaid: boolean;
    registrationDate: Date;
    qrCodeSent: boolean;
    isExpanded?: boolean;
};

/*
const mapFromRest = (flow: RestSignUpFlow): SignUpFlow => {
    return {
        code: flow.code,
        description: flow.description,
        isCustom: flow.isCustom,
        title: flow.title,
        content: buildForm(flow.sections),
    };
};
*/

export const getAccreditations = async (
    keyword: string,
    accessToken: string,
): Promise<TempAccreditation[]> => {
    let url = new URL(
        'https://backend.injuregrindattack.pl/tempAccreditations/search',
    );
    //let url = new URL('https://localhost:44357/tempAccreditations/search');

    if (keyword) {
        url.search = new URLSearchParams({
            keyword,
        }).toString();
    }

    const response = await fetch(url.toString(), {
        headers: {
            authorization: `bearer ${accessToken}`,
        },
    });

    const data = await response.json();

    return data as TempAccreditation[];
};

const postData = async (url = '', data: any, accessToken: string) => {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            authorization: `bearer ${accessToken}`,
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.status === 400) {
        return Promise.reject();
    }

    return Promise.resolve();
    // return await response.json(); // parses JSON response into native JavaScript objects
};

export const confirm = async (id: number, accessToken: string) => {
    await postData(
        'https://backend.injuregrindattack.pl/tempAccreditations/confirm',
        //'https://localhost:44357/tempAccreditations/confirm',
        id,
        accessToken,
    ).then(() => Promise.resolve(true));
};

export const sendQRCode = async (id: number, accessToken: string) => {
    await postData(
        'https://backend.injuregrindattack.pl/participant/pdf',
        id,
        accessToken,
    ).then(() => Promise.resolve(true));
};

export const sendSummaryEmail = async (id: number, accessToken: string) => {
    await postData(
        'https://backend.injuregrindattack.pl/participant/sendEmail',
        //'https://localhost:44357/participant/sendEmail',
        id,
        accessToken,
    ).then(() => Promise.resolve(true));
};

/*
return fetch('https://localhost:44357/flows/' + flowID).then((response) =>
        mapFromRest(response.json() as Promise<RestSignUpFlow>),
    );
*/
