/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpDummy: React.FC = () => {
    return (
        <div
            css={css`
                font-size: 20px;
                text-align: center;
                margin-top: 50px;
                margin-bottom: 50px;
            `}
        >
            Sprzedaż internetowa biletów na Injure Grind Attack V została
            zakończona
            <br />
            Bilety dostępne będą na bramce - płatne tylko gotówką: <br />
            Jednodniowe - 180 zł <br />
            Dwudniowe - 300 zł
        </div>
    );
};

export default SignUpDummy;
